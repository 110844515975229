export class NavItem {
  label = ''
  routeName = ''
  routeParams = null

  constructor ({ label, labelMobile, onlyMobile, routeName, routeParams }) {
    this.label = label
    this.onlyMobile = onlyMobile ?? null
    this.routeName = routeName
    this.labelMobile = labelMobile ?? null
    this.routeParams = routeParams ? { ...routeParams } : null
  }
}
