<template>
  <transition name="fade">
    <VForm
      v-model="formData"
      class="form-filter gap-m"
      ref="form"
      @request="onSubmit"
    >
      <div class="grid-filter">
        <slot/>
      </div>

      <div class="buttons">
        <VButton
          text="Применить"
          type="submit"
          :class="{ 'btn--100' : isMobile }"
          color="primary"
        />

        <VButton
          text="Сбросить"
          :class="{ 'btn--100' : isMobile }"
          @click.native="onReset"
        />
      </div>
    </VForm>
  </transition>
</template>

<script>
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import { mixinFormData } from '@/mixins/form/mixinFormData'
import { mapState } from 'vuex'

export default {
  name: 'FilterForm',
  components: { VButton, VForm },
  mixins: [mixinFormData],
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    })
  },
  methods: {
    onSubmit (data) {
      this.$emit('request', data)
    },
    onReset () {
      this.$refs.form.clear()
      this.$emit('reset')
    }
  }
}
</script>
