<template>
  <div :class="['input', { readonly, disabled }]" :style="{ width: width }">
    <component
      :is="componentType"
      v-model.trim="modelValue"
      ref="input"

      :inputWidth="inputWidth"
      :type="type"
      :input-type="inputType"
      :label="label"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :multiple="multiple"
      :email="email"
      :loading="loading"
      :autocomplete="autocomplete"
      :withCopy="withCopy"
      :required="required"
      :pattern="pattern"
      :mask-regex="maskRegex"
      :limit-max-length="limitMaxLength"
      :accept="accept"
      :unmask-on-input="unmaskOnInput"
      :max-file-size="maxFileSize"
      :max-file-count="maxFileCount"
      :min-length="minLength"
      :max-length="maxLength"
      :server="server"
      :same-as="sameAs"
      :not="not"
      :min-value="minValue"
      :max-value="maxValue"
      :custom-validation="customValidation"
      :rolled="rolled"
      @input="handleInput"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @keydown="$emit('keydown')"
      @keyup="$emit('keyup')"
      @keypress="$emit('keypress')"
      @change="$emit('change')"
      @keydown.enter="$emit('enter')"
      @paste="$emit('paste')"
      @copyField="$emit('copyField')"
      @onEnter="onEnter"
    >
      <slot />
    </component>

    <slot name="after" />
  </div>
</template>

<script>
import Vue from 'vue'
import InputProps from './Base/InputProps'

import VText from './Text'
import VPassword from './Password'
import VSearch from './Search'
import VTextarea from './Textarea'
import { mixinInputModel } from '@/mixins/form/mixinInputModel'

const componentMap = {
  search: 'VSearch',
  password: 'VPassword',
  textarea: 'VTextarea'
}

export default Vue.extend({
  name: 'VInput',
  emits: ['enter'],
  extends: InputProps,
  mixins: [mixinInputModel],
  components: {
    VPassword,
    VText,
    VSearch,
    VTextarea
  },
  props: {
    maskRegex: [String, RegExp],
    required: Boolean,
    server: Function,
    pattern: [String, RegExp],
    minLength: String,
    maxLength: String,
    sameAs: String,
    not: String,
    maxValue: [Number, null],
    minValue: [Number, null],
    email: Boolean,
    unmaskOnInput: {
      type: Boolean,
      default: false
    },
    customValidation: {
      type: [Function, null],
      default: null
    },
    validateOnInput: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String
    },
    // for file type input
    maxFileSize: {
      type: Number,
      default: Infinity
    },
    maxFileCount: {
      type: Number,
      default: Infinity
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },

    //  icon
    withCopy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    componentType () {
      return componentMap[this.type] || 'VText'
    },
    hasValidationError () {
      return this.$refs.input?.hasValidationError
    }
  },
  methods: {
    touch () {
      const touchValidator = this.$refs.input?.$v?.$touch
      touchValidator && touchValidator()
    },
    focus () {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.input?.focus()
    },
    handleInput (val) {
      if (this.validateOnInput) this.touch()
      this.$emit('input', val)
    },
    onEnter (val) {
      this.$emit('onEnter', val)
    },
    clear () {
      this.modelValue = null
    }
  }
})
</script>
