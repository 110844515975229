const state = () => ({
  windowWidth: window.innerWidth,
  isMobile: window.innerWidth < 1071
})

const mutations = {
  SET_IS_MOBILE (state, isMobile) {
    state.isMobile = isMobile
  },
  SET_WINDOW_WIDTH (state, width) {
    state.windowWidth = width
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
