/* eslint-disable */
require('./add')
require('./arrow-back')
require('./arrow-next')
require('./arrow-pagination')
require('./arrow')
require('./changelog')
require('./comment')
require('./copy')
require('./create')
require('./edit')
require('./export')
require('./eye')
require('./filter')
require('./import')
require('./info')
require('./notification')
require('./packaging')
require('./pickup')
require('./plus')
require('./print')
require('./profile')
require('./refresh')
require('./remove')
require('./reset-sort')
require('./reset')
require('./restore')
require('./rollup')
require('./scroll-up')
require('./set-executor')
require('./settings')
require('./swiper-arrow')
require('./viewtype-cards')
require('./viewtype-table')
require('./warn')
require('./weight')
