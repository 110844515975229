<template>
  <header class="header p-l" :class="{ 'open-menu': headerMenuIsActive }">
    <div class="header__mobile-menu-button">
      <VButton
        block="burger"
        size="menu"
        :active="headerMenuIsActive"
        @click.native="toggleHeaderMenu"
      />
    </div>

    <router-link
      v-slot="{ href, navigate }"
      :to="{ name: permissionOpenConsignment ? 'ConsignmentList' : 'Production' }"
      custom
    >
      <span @click="navigate" class="header__logo">
        <a :href="href" @click="resetFilter">
          <img :src="require('@/assets/images/logo.svg')" style="height: 100%;" alt="Пружинный проект">
        </a>
      </span>
    </router-link>

    <nav class="header__menu-tabs p-xl">
      <TabsSwiper>
        <NavItem
          class="swiper-slide"
          v-for="(item, index) in headerMenu"
          :key="index"
          :navItem="item"
          block="menu"
          isResetFilter
        />
      </TabsSwiper>
    </nav>

    <div class="buttons" style="margin-left: auto;">
      <VButton
        v-if="false"
        size="menu"
        :icon="{ name: 'settings', fill: true }"
      />

      <VButton
        v-if="false"
        size="menu"
        :icon="{ name: 'notification', width: '15', height: '18', fill: true, }"
        :counter="1"
      />

      <VButton
        size="menu"
        :icon="{ name: 'profile', width: '15', height: '18', fill: true }"
        :active="userMenu.isActive"
        @click.native="toggleUserMenu"
        @blur.native="closeUserMenu"
      />
    </div>

    <transition name="fade">
      <nav
        class="header__menu"
        v-if="headerMenuIsActive"
        @click.self="closeUserMenu"
      >
        <ul class="nav-list--mobile">
          <NavItem
            v-for="(item, index) in headerMenu"
            :key="index"
            :navItem="item"
            block="menu"
            @onClick="toggleHeaderMenu"
            isResetFilter
          />
        </ul>
      </nav>
    </transition>

    <transition name="fade">
      <div v-if="userMenu.isActive" class="header__user-menu">
        <span class="header__user-menu-info">
          {{ currentUserFullName }}
        </span>

        <span class="header__user-menu-info">
          Роль: {{ currentUser ? currentUser.roles[0].name_translate : '-' }}
        </span>

        <span v-if="isAvailableAdminPanel">
          <span
            v-for="item in userMenu.list"
            :key="item.title"
            class="header__user-menu-item"
            @click="goToPage(item.route.name)"
          >
            {{ item.title }}
          </span>
        </span>

        <a
          class="header__user-menu-item"
          href="#"
          @click.prevent="logout"
        >
          Выход
        </a>
      </div>
    </transition>
  </header>
</template>

<script>
import NavItem from '@/components/ui/Nav/NavItem'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper'
import VButton from '@/components/simple/button/VButton'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'

export default {
  name: 'TheHeader',
  components: {
    NavItem,
    TabsSwiper,
    VButton
  },

  data: () => ({
    userMenu: {
      isActive: false,
      list: [
        {
          title: 'Панель администратора',
          route: {
            name: 'AdminPanel',
            menu: 'adminPanel'
          }
        }
      ]
    }
  }),

  computed: {
    ...mapState('menu', {
      headerMenu: 'headerMenu',
      headerMenuIsActive: 'headerMenuIsActive'
    }),
    ...mapGetters({
      currentUser: 'currentUser',
      currentUserFullName: 'currentUserFullName'
    }),
    ...mapGetters('permission', {
      permissionOpenConsignment: 'permissionOpenConsignment'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    isAvailableAdminPanel () {
      return this.currentUser?.sections.includes('isAdminPanel')
    }
  },

  methods: {
    ...mapMutations('menu', {
      SET_HEADER_MENU_ACTIVE: 'SET_HEADER_MENU_ACTIVE'
    }),
    goToPage,
    toggleUserMenu () {
      this.userMenu.isActive = !this.userMenu.isActive
    },
    closeUserMenu () {
      this.userMenu.isActive = false
    },
    toggleHeaderMenu () {
      this.SET_HEADER_MENU_ACTIVE()
    },
    async logout () {
      await this.$store.dispatch('logout')
      await this.$router.push('/auth')
    },

    resetFilter () {
      if (this.isMobile) {
        this.$store.commit('registerPage/RESET_FILTER')
        this.$store.commit('registerPage/RESET_SEARCH')
        this.$store.commit('registerPage/RESET_DATE_ORDER_CREATE')
        this.$store.commit('order/RESET_FILTER_BY_DATE_STATUS')
        this.$store.commit('order/SET_FILTER', {})
        this.$store.commit('consignment/SET_FILTER', {})
      }
    }
  }
}
</script>
