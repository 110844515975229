/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'util': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.669 9.5H5.33a.5.5 0 00-.494.576l1.385 9a.5.5 0 00.494.424h8.568a.5.5 0 00.494-.424l1.385-9a.5.5 0 00-.494-.576zM5.33 8a2 2 0 00-1.977 2.304l1.385 9A2 2 0 006.716 21h8.568a2 2 0 001.977-1.696l1.384-9A2 2 0 0016.67 8H5.33zM10.374 4.296H4.59a.45.45 0 100 .9h12.466a.45.45 0 100-.9h-5.782V2.497a.45.45 0 00-.901 0v1.799zm2.4-1.5v-.299a1.95 1.95 0 00-3.9 0v.299H4.59c-1.532 0-2.466 1.685-1.654 2.984.356.57.981.917 1.654.917h12.466a1.95 1.95 0 001.654-.917c.812-1.3-.122-2.984-1.654-2.984h-4.282z" _fill="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.735 10.853a.75.75 0 01-.588.883l-3.323.664 3.323.665v1.47l-3.323.665 3.323.665v1.47l-7 1.4a.75.75 0 01-.294-1.47l3.323-.665-3.323-.665v-1.47l3.323-.665-3.323-.665v-1.47l7-1.4a.75.75 0 01.882.588z" _fill="currentColor"/>'
  }
})
