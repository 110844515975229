import router from '@/router'
import { HEADER_NAV } from '@/const/nav/HeaderNav'
import { SIDEBAR_NAV } from '@/const/nav/SidebarNav'

const state = () => ({
  headerMenu: [],
  sidebarMenu: [],
  headerMenuIsActive: false
})

const mutations = {
  SET_HEADER_MENU: (state, menu) => {
    state.headerMenu = menu
  },
  SET_SIDEBAR_MENU: (state, menu) => {
    state.sidebarMenu = menu
  },
  CLEAR_STATE: state => {
    state.headerMenu = []
    state.sidebarMenu = []
  },
  SET_HEADER_MENU_ACTIVE: (state) => {
    state.headerMenuIsActive = !state.headerMenuIsActive
  }
}

const actions = {
  setHeaderMenu ({ commit, rootGetters }) {
    const userAccessMenu = [...rootGetters.currentUser?.sections]

    // TODO CATALOG удалить после добавления на беке
    if (rootGetters['permission/permissionReadCatalog']) {
      userAccessMenu.push('isCatalog')
    }

    if (rootGetters['permission/permissionOpenConsignment'] && HEADER_NAV[0].routeName === 'Production') {
      HEADER_NAV[0].routeName = 'ConsignmentList'
    }

    const headerNav = HEADER_NAV.filter(item => userAccessMenu.includes(item.accessKey))

    commit('SET_HEADER_MENU', headerNav)
  },

  async setSidebarMenu ({ commit, rootGetters, dispatch, rootState }) {
    const sidebarNav = []

    switch (router.currentRoute.meta.availableName) {
      case 'isProduction':
        sidebarNav.push(SIDEBAR_NAV.production[0])
        sidebarNav.push(SIDEBAR_NAV.production[1])

        if (rootGetters['permission/permissionCreateTemplatePosition']) {
          sidebarNav.push(SIDEBAR_NAV.production[2])
        }

        if (rootGetters['permission/permissionReadChangelog']) {
          sidebarNav.push(SIDEBAR_NAV.production[3])
        }
        break
      case 'isCatalog': {
        const catalogMenu = await dispatch('catalog/getCatalogSidebarAndSetBreadcrumbsNav', null, { root: true })
        sidebarNav.push(...catalogMenu)
        break
      }
      case 'isWarehouse':
        sidebarNav.push(...SIDEBAR_NAV.warehouse)
        break
      case 'isAnalytics':
        sidebarNav.push(...SIDEBAR_NAV.analytics)
        break
      case 'isAdminPanel':
        sidebarNav.push(...SIDEBAR_NAV.adminPanel)

        if (!rootGetters['permission/permissionCreateHandbook']) {
          sidebarNav.pop()
        }
        break
      case 'isReclamation':
        sidebarNav.push(...SIDEBAR_NAV.reclamationSidebar)
        break
      case 'isLoadCalendar':
        sidebarNav.push(...SIDEBAR_NAV.loadCalendar)
        break
    }
    commit('SET_SIDEBAR_MENU', sidebarNav)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
