import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import JsonExcel from 'vue-json-excel'
import svgicon from 'vue-svgicon'
import Vuelidate from 'vuelidate'
import Spinner from '@/components/ui/Spinner'
import VueClipboard from 'vue-clipboard2'

import { dateFilter } from './filters/date.filter'
import stringMaxLength from './filters/stringMaxLength.filter'

import VueHtmlToPaper from 'vue-html-to-paper'

import '@/icons'
import '@/assets/styles/main.scss'
import './registerServiceWorker'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: ['/print-style.css'],
  timeout: 1000,
  autoClose: true,
  windowTitle: window.document.title
}

Vue.filter('date', dateFilter)
Vue.filter('stringMaxLength', stringMaxLength)
Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.use(VueHtmlToPaper, options)

Vue.use(svgicon, {
  tagName: 'svgicon',
  isStroke: true
})

Vue.component('downloadExcel', JsonExcel)
Vue.component('Spinner', Spinner)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
