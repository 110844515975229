<template>
  <div class="tooltip">
    <svgicon
      v-if="hasIcon"
      icon="notification-question"
      class="tooltip__icon svg-icon--question"
      width="22"
      height="22"
    />

    <slot>{{ text | stringMaxLength(maxLength) }}</slot>

    <transition name="fade">
      <span v-if="visiblePopup" class="tooltip__popup" :class="'tooltip__popup--' + tooltipSide">
        {{ popupText || text }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VTooltip',

  props: {
    tooltipSide: {
      type: String,
      default: 'bottom'
    },
    text: {
      type: [String, Number]
    },
    maxLength: {
      type: Number
    },
    popupText: {
      type: [String, Number]
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    showTip: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    visiblePopup () {
      return this.showTip || `${this.text}`.length > this.maxLength
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
  word-break: break-all;
  cursor: pointer;

  &__popup {
    --popup-gap: 8px;

    position: absolute;
    z-index: 10;
    left: 110%;
    min-height: 30px;
    min-width: 160px;
    width: 100%;
    border: 1px solid var(--color__gray-blue);
    border-radius: 12px;
    padding: 12px 16px;
    @include font12();
    text-align: center;
    color: $color__third;
    word-break: initial;
    background-color: var(--bg-color);
    visibility: hidden;
    animation: fadeIn .3s ease-in;

    &::before {
      content: '';
      display: block;
      position: absolute;
      margin: 0 auto;
      width: 10px;
      height: 10px;
      background-color: var(--bg-color);
      border: 1px solid var(--color__gray-blue);
    }

    &--top,
    &--bottom {
      left: 50%;
      transform: translateX(-50%);

      &::before {
        left: 0;
        right: 0;
        border-bottom: none;
        border-right: none;
      }
    }

    &--bottom {
      top: calc(100% + var(--popup-gap));

      &:before {
        top: -6px;
        transform: rotate(45deg);
      }
    }

    &--top {
      bottom: calc(100% + var(--popup-gap));

      &:before {
        bottom: -6px;
        transform: rotate(225deg);
      }
    }

    &--right {
      top: -10px;
      left: auto;
      right: -176px;

      &:before {
        top: 16px;
        left: -6px;
        border-bottom: none;
        border-right: none;
        transform: rotate(-45deg);
      }
    }

    @media screen and (max-width: $tablet-width-s) {
      max-height: 120px;
      overflow: auto;
    }
  }

  &__icon {
    color: var(--color__gray-blue);
    transition: var(--transition-duration) color ease-in-out;
  }

  &:hover {
    .tooltip__icon {
      color: var(--color__cyan);
    }

    .tooltip__popup {
      visibility: visible;
    }
  }
}
</style>
