import { NavItem } from '@/classes/nav/NavItem'

export class SidebarNavItem extends NavItem {
  icon = 'nav/sidebar/'

  constructor ({ label, labelMobile, onlyMobile, routeName, routeParams, icon, iconFullPath }) {
    super({ label, labelMobile, onlyMobile, routeName, routeParams })

    this.icon = iconFullPath || this.icon + icon
  }
}
