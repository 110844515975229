/* eslint-disable camelcase */
import axios from 'axios'
import router from '@/router'
import { getAll, create, update } from '@/api/request'
import { getOrderStatusIdByRouteName } from '@/utils/order/order'
import { deleteKeyFormData } from '@/utils/form/deleteKeyFormData'
import { getPageCount } from '@/utils/store/pagination'
import { setAlertError } from '@/utils/store/alert'
import OrderController from '@/controllers/production/Order/OrderController'

const state = () => ({
  url: 'order/orders',
  urlAmount: 'order/amount',

  orderList: [],
  isLoadingOrderList: false,

  countOrderListPositions: 0,

  countByDateStatus: {
    overdue: 0,
    deadline_is_coming: 0
  },

  // Query
  pagination: {
    perPage: 20,
    page: 1,
    pageCount: 1
  },

  filter: {},
  filterByDateStatus: {
    overdue: false,
    deadline_is_coming: false
  }
})

const getters = {
  getQueryParams: (state, _, rootState) => {
    const params = {
      account_number: rootState.registerPage.search || null,
      ...state.filter,
      overdue: state.filterByDateStatus.overdue || null,
      deadline_is_coming: state.filterByDateStatus.deadline_is_coming || null
    }

    const deleteKeyList = [
      'responsible_id',
      'stage'
    ]

    return deleteKeyFormData(params, deleteKeyList)
  },
  overdueCount: state => state.countByDateStatus.overdue,
  deadlineIsComingCount: state => state.countByDateStatus.deadline_is_coming
}

const mutations = {
  SET_ORDER_LIST (state, orders) {
    state.orderList = [...orders]
  },
  SET_COUNT_ORDER_LIST_POSITIONS (state, { statusId, data }) {
    const countOrderListPositionsKeyList = [
      'positions_new',
      'positions_work',
      'positions_complete',
      'positions_delivery',
      'positions_archive',
      'positions_launch'
    ]
    const dataKey = countOrderListPositionsKeyList[statusId - 1]

    state.countOrderListPositions = data[dataKey]
  },

  SET_COUNT_BY_DATE_STATUS (state, { overdue = 0, deadline_is_coming = 0 }) {
    state.countByDateStatus.overdue = overdue
    state.countByDateStatus.deadline_is_coming = deadline_is_coming
  },

  SET_IS_LOADING_ORDER_LIST (state, loading) {
    state.isLoadingOrderList = loading
  },

  // Query
  SET_FILTER (state, payload) {
    state.filter = payload
  },
  SET_PAGE (state, pageNumber) {
    state.pagination.page = pageNumber
  },
  SET_PAGINATION_PAGE_COUNT (state, pageCount) {
    state.pagination.pageCount = pageCount
  },

  TOGGLE_FILTER_OVERDUE (state) {
    state.filterByDateStatus.overdue = !state.filterByDateStatus.overdue
  },
  TOGGLE_FILTER_DEADLINE_IS_COMING (state) {
    state.filterByDateStatus.deadline_is_coming = !state.filterByDateStatus.deadline_is_coming
  },
  RESET_FILTER_BY_DATE_STATUS (state) {
    for (const key in state.filterByDateStatus) {
      state.filterByDateStatus[key] = false
    }
  },

  // RESET
  CLEAR_STATE (state) {
    state.orderList = []
    state.search = ''
    state.countOrderListPositions = 0
  }
}

const actions = {
  async fetchOrderListData ({ commit, dispatch }) {
    commit('SET_IS_LOADING_ORDER_LIST', true)

    try {
      await axios.all([
        dispatch('loadOrderList'),
        dispatch('getAmountTab'),
        dispatch('loadOrderCountByDateStatus')
      ])
    } finally {
      commit('SET_IS_LOADING_ORDER_LIST', false)
    }
  },
  async loadOrderList ({ state, commit, getters }) {
    const params = {
      status_id: getOrderStatusIdByRouteName(router.currentRoute.name),
      sort: router.currentRoute.name === 'ArchiveOrderList' ? '-id' : 'id',
      ...getters.getQueryParams,
      'per-page': state.pagination.perPage,
      page: state.pagination.page
    }

    commit('SET_ORDER_LIST', [])

    const res = await OrderController.getAll(params)
    if (res?.status !== 200) return
    commit('SET_ORDER_LIST', res.data)
    commit('SET_PAGINATION_PAGE_COUNT', getPageCount(res))
  },
  async getAmountTab ({ state, commit, getters }) {
    try {
      const res = await getAll(state.urlAmount, getters.getQueryParams)
      commit(
        'tabsOrderList/SET_TABS_AMOUNT',
        { tabsName: 'Order', data: res.data },
        { root: 'true' }
      )
      commit('SET_COUNT_ORDER_LIST_POSITIONS', {
        statusId: getOrderStatusIdByRouteName(router.currentRoute.name),
        data: res.data
      })
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить количество заказов по статусам')
    }
  },
  async loadOrderCountByDateStatus ({ commit }) {
    try {
      const response = await getAll('order/date-status-count')
      commit('SET_COUNT_BY_DATE_STATUS', response.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить количество заказов по срокам')
    }
  },

  async loadPage ({ commit, dispatch }, pageNumber) {
    commit('SET_PAGE', pageNumber)
    await dispatch('fetchOrderListData')
  },

  async createOrder ({ state, rootGetters }, postData) {
    const dataOrder = {
      user_id: rootGetters.currentUserId,
      ...postData
    }
    try {
      const response = await create(state.url, dataOrder)
      return response.data
    } catch (e) {
      await setAlertError(e, 'Не удалось создать заказ')
    }
  },
  async updateOrder ({ state, rootGetters }, postData) {
    const updateData = {
      user_id: rootGetters.currentUserId,
      ...postData
    }
    try {
      const response = await update(state.url, postData.id, updateData)
      return response.data
    } catch (e) {
      await setAlertError(e, 'Не удалось обновить заказ')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
