import { SidebarNavItem } from '@/classes/nav/SidebarNavItem'
import { sidebar as loadCalendar } from '@/views/LoadCalendar'
import { reclamationSidebar } from '@/views/Reclamation'

const production = [
  {
    label: 'Заказы',
    routeName: 'Order',
    icon: 'production-spring'
  },
  {
    label: 'Партии',
    routeName: 'ConsignmentList',
    icon: 'production-consignment'
  },
  {
    label: 'Шаблоны',
    routeName: 'ProductionTemplate',
    icon: 'production-template'
  },
  {
    label: 'Журнал изменений',
    routeName: 'ChangeLog',
    icon: 'changelog'
  }
]

const warehouse = [
  {
    label: 'Материалы',
    routeName: 'WarehouseMaterials',
    icon: 'warehouse-material'
  },
  {
    label: 'Заделы',
    routeName: 'WarehouseReserve',
    icon: 'warehouse-reserve'
  }
]

const analytics = [
  {
    label: 'Аналитика',
    routeName: 'Analytics',
    icon: 'analytics'
  }
]

const adminPanel = [
  {
    label: 'Пользователи',
    routeName: 'Users',
    icon: 'admin-user'
  },
  {
    label: 'Цепочки\nзаказов',
    routeName: 'OrderChain',
    icon: 'admin-chain'
  },
  {
    label: 'Справочник',
    routeName: 'DirectoriesList',
    icon: 'admin-handbook'
  }
]

export const SIDEBAR_NAV = {
  production,
  warehouse,
  analytics,
  adminPanel,
  loadCalendar,
  reclamationSidebar
}

for (const key in SIDEBAR_NAV) {
  SIDEBAR_NAV[key] = SIDEBAR_NAV[key].map(navItem => new SidebarNavItem(navItem))
}
