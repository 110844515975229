export const mixinFilterEmit = {
  methods: {
    request (data, originalData = {}) {
      this.$emit('request', data, originalData)
    },
    reset () {
      this.$emit('reset', {}, {})
    }
  }
}
