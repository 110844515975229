/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/production-consignment': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" _stroke="currentColor" d="M3 2.5h17v17H3z"/><rect pid="1" width="8.668" height="1.059" rx=".53" transform="matrix(-.97596 -.21794 -.10574 .9944 16.072 13.25)" _fill="currentColor"/><rect pid="2" width="8.693" height="1.058" rx=".529" transform="matrix(.97053 -.241 .11743 .99308 7.505 15.426)" _fill="currentColor"/><rect pid="3" width="8.693" height="1.058" rx=".529" transform="matrix(.97053 -.241 .11743 .99308 7.505 11.283)" _fill="currentColor"/><rect pid="4" width="8.673" height="1.059" rx=".53" transform="matrix(-.97476 -.22326 -.10843 .9941 16.07 9.108)" _fill="currentColor"/><rect pid="5" width="8.693" height="1.058" rx=".529" transform="matrix(.97053 -.241 .11743 .99308 7.505 7.095)" _fill="currentColor"/>'
  }
})
