<template>
  <button
    class="btn-reset"
    :class="classListButton"
    :type="type"
    :disabled="disabled || loading"
  >
    <!--  icon   -->
    <span
      v-if="getIcon"
      class="btn__icon-wrap"
      :class="classListIconWrap"
    >
      <svgicon
        class="btn__icon"
        :class="'svg-icon--' + currentIcon"
        :icon="getIcon"
        :width="getIconWidth"
        :height="getIconHeight"
        :fill="icon.fill"
        :dir="icon.dir"
      />
    </span>

    <!--  burger   -->
    <div v-if="block === 'burger'" class="btn__icon-wrap">
      <div class="btn__burger">
        <span class="btn__burger-line"/>
        <span class="btn__burger-line"/>
        <span class="btn__burger-line"/>
      </div>

    </div>

    <!--  counter   -->
    <transition name="fade">
      <div v-if="counter" class="btn__counter" :class="'bg-color--' + counterColor">
        {{ counter }}
      </div>
    </transition>

    <!--  text   -->
    <span v-if="text" class="btn__text" :class="classListText" >
      {{text}}
    </span>

    <!--  loader   -->
    <SpinnerSimple
      v-if="loading"
      class="btn__loader"
      :class="classListLoader"
    />
  </button>
</template>

<script>
import SpinnerSimple from '@/components/ui/loaders/SpinnerSimple/SpinnerSimple.vue'

export default {
  components: { SpinnerSimple },
  props: {
    // block - для указания типа кнопки
    block: {
      type: String,
      required: false,
      default: ''
    },
    // icon from @/icons/button
    // template
    // :icon="{ name: '', width: '', height: '', fill: false, dir: '' }"
    // fill: для fill: currentColor
    // dir: для поворота иконки на 90, 180, 270
    icon: {
      type: [Object, String],
      required: false,
      default: ''
    },
    // row-reverse, column, column-reverse
    direction: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: ''
    },
    // size
    // menu - 45x45
    // sm - выделена иконка 36x36,
    // md - выделена иконка 43x43,
    // min-width:
    // l - 180px,
    // xl - 240px,
    // xxl - 350px,
    // 100 - 100%
    size: {
      type: String,
      required: false,
      default: 'm'
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isNotification: {
      type: Boolean,
      required: false,
      default: false
    },
    counter: {
      type: Number,
      required: false,
      default: 0
    },
    counterColor: {
      type: String,
      default: 'red',
      validator: (value) => ['red', 'orange', 'green'].includes(value)
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    currentIcon () {
      return this.icon.name || this.icon
    },

    getIcon () {
      return this.currentIcon ? `button/${this.currentIcon}` : ''
    },

    getIconWidth () {
      if (this.icon.width) return this.icon.width

      switch (this.currentIcon) {
        case 'add': return '17'
        case 'arrow-next': return '17'
        case 'arrow-back': return '14'
        case 'arrow-pagination': return '17'
        case 'changelog': return '19'
        case 'comment': return '19'
        case 'create': return '13'
        case 'edit': return '18'
        case 'export': return '18'
        case 'import': return '14'
        case 'eye': return '20'
        case 'filter': return '18'
        case 'notification': return '16'
        case 'pickup': return '23'
        case 'plus': return '24'
        case 'print': return '19'
        case 'profile': return '16'
        case 'remove': return '15'
        case 'reset': return '17'
        case 'rollup': return '13'
        case 'scroll-up': return '18'
        case 'settings': return '20'
        case 'swiper-arrow': return '9'
        case 'warn': return '16'
        case 'weight': return '14'
        case 'restore': return '25'
        case 'copy': return '15'
        case 'viewtype-cards': return '22'
        case 'viewtype-table': return '22'
        case 'info': return '18'
        case 'set-executor': return '14'
        case 'reset-sort': return '20'
        case 'arrow': return '16'
        case 'packaging': return '15'
        default: return '0'
      }
    },

    getIconHeight () {
      if (this.icon.height) return this.icon.height

      switch (this.currentIcon) {
        case 'add': return '17'
        case 'arrow-next': return '9'
        case 'arrow-back': return '10'
        case 'arrow-pagination': return '13'
        case 'changelog': return '18'
        case 'comment': return '18'
        case 'create': return '13'
        case 'edit': return '18'
        case 'export': return '18'
        case 'import': return '14'
        case 'eye': return '20'
        case 'filter': return '20'
        case 'notification': return '19'
        case 'pickup': return '23'
        case 'plus': return '24'
        case 'print': return '18'
        case 'profile': return '18'
        case 'remove': return '18'
        case 'reset': return '18'
        case 'rollup': return '9'
        case 'scroll-up': return '16'
        case 'settings': return '20'
        case 'swiper-arrow': return '17'
        case 'warn': return '16'
        case 'weight': return '21'
        case 'restore': return '24'
        case 'copy': return '19'
        case 'viewtype-cards': return '22'
        case 'viewtype-table': return '22'
        case 'info': return '18'
        case 'set-executor': return '18'
        case 'reset-sort': return '20'
        case 'arrow': return '16'
        case 'packaging': return '17'
        default: return '0'
      }
    },

    classListButton () {
      const classes = []

      const btnMod = 'btn--'

      if (this.block) {
        classes.push('btn-' + this.block)
      }

      switch (this.size) {
        case 'sm':
        case 'menu': break
        default: if (!this.block) classes.push('btn')
      }

      if (this.direction) {
        classes.push(btnMod + this.direction)
      }

      if (this.color) {
        classes.push(btnMod + this.color)
      }

      classes.push(btnMod + this.size)

      if (this.active) {
        classes.push('active')
      }

      return classes
    },

    classListLoader () {
      const isSm = this.size === 'sm'
      return {
        'btn__loader--white': (this.color === 'primary') || isSm,
        'btn__loader--sm': isSm
      }
    },

    classListIconWrap () {
      const classes = []
      if (this.icon?.classes) {
        classes.push(...this.icon.classes)
      }
      if (this.isNotification) {
        classes.push('btn__icon-wrap--notification')
      }

      if (this.loading && this.size !== 'sm') {
        classes.push('opacity-0')
      }
      return classes
    },

    classListText () {
      return {
        transparent: this.loading && this.size !== 'sm'
      }
    }
  }
}
</script>
