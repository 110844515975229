<template>
  <router-link
    v-if="showItem"
    v-slot="{ href, navigate, isActive }"
    custom
    :to="{ name: navItem.routeName, params: navItem.routeParams || {} }"
  >
    <li
      class="nav-item"
      :class="[
        isActive && !isActiveCons && 'router-link-active',
        isDisabled && 'disabled',
        block + '-item'
      ]"
      @click="navigate"
    >
      <a
        :href="href"
        class="nav-item__link"
        :class="block + '-item__link'"
        @click="onClick"
      >
        <svgicon
          v-if="navItem.icon"
          class="nav-item__icon"
          :class="block + '-item__icon' + ' svg-icon--' + iconName"
          :icon="iconPrefix + navItem.icon"
          :width="iconSize.width"
          :height="iconSize.height"
        />

        <TextCount
          class="nav-item__text"
          :class="block + '-item__text'"
          :text="label"
          :count="navItem.count"
        />
      </a>
    </li>
  </router-link>
</template>

<script>
import TextCount from '@/components/simple/text/TextCount'
import { mixinNavItem } from '@/mixins/mixinNavItem'
import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'NavItem',
  components: {
    TextCount
  },
  mixins: [mixinNavItem, mixinIsFilter],
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    iconSize () {
      return this.navItem?.iconSize || { width: '20', height: '20' }
    },
    iconName () {
      return this.navItem?.icon?.split('/').at(-1) || ''
    },
    label () {
      return this.isMobile && this.navItem.labelMobile ? this.navItem.labelMobile : this.navItem.label
    },
    showItem () {
      return !this.navItem?.hidden
    },

    // TODO Для того чтобы в производстве вкладка Заказ не была активной при нахождении на странице партий
    isActiveCons () {
      return this.$route.path.includes('production/order/consignment-list') ? (this.navItem.routeName === 'Order') : false
    }
  },
  methods: {
    ...mapState('catalog', {
      filterList: 'filterList'
    }),
    ...mapGetters('catalog', {
      currentFilterSetting: 'currentFilterSetting'
    }),
    onClick () {
      if (this.isResetFilter) {
        this.resetFilter()
      }
      this.$emit('onClick')
    },
    resetFilter () {
      if (this.$route.meta.availableName === 'isProduction') {
        this.$store.commit('registerPage/RESET_FILTER')
        this.$store.commit('registerPage/RESET_SEARCH')
        this.$store.commit('registerPage/RESET_DATE_ORDER_CREATE')
        this.$store.commit('order/RESET_FILTER_BY_DATE_STATUS')
        this.$store.commit('order/SET_FILTER', {})
        this.$store.commit('consignment/SET_FILTER', {})
      }
      if (this.$route.meta.availableName === 'isCatalog') {
        this.$store.commit('catalog/RESET_SEARCH')
        this.$store.commit('catalog/RESET_FILTER_FORM_VALUE')
        this.$store.commit('catalog/RESET_FILTER')
        this.$store.commit('catalog/SET_PAGE', 1)
        this.$store.dispatch('catalog/product/getProductsBy', null)
      }
      if (this.$route.meta.availableName === 'isWarehouse') {
        this.$store.commit('warehouse/material/RESET_SEARCH')
        this.$store.commit('warehouse/material/SET_PAGE', 1)
        this.$store.dispatch('warehouse/material/getMaterials')
      }
      if (this.$route.meta.availableName === 'isReclamation') {
        this.$store.commit('reclamationExternal/filterList/RESET_SEARCH')
        this.$store.commit('reclamationExternal/filterList/RESET_FILTER')
        this.$store.commit('reclamationExternal/filterList/RESET_DATE')
        this.$store.commit('reclamationExternal/filterList/SET_PAGE', 1)
        // this.$store.dispatch('reclamationExternal/filterList/fetchReclamations')
        this.$store.dispatch('reclamationExternal/filterList/fetchStatusesCount')
      }
      if (this.$route.meta.availableName === 'isAnalytics') {
        this.$store.commit('records/RESET_SELECTED_REPORT')
        this.$store.commit('records/SET_IS_RESET_RECORD', true)
      }
    }
  }
}
</script>
