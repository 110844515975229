<template>
<!-- TODO настроить transition  -->
<!--  <transition name="fade">-->
    <div
      class="modal"
      :class="activeClass"
    >
      <div
        class="modal__wrap"
        @mousedown.self="onClose"
      >
        <div class="modal__content">
          <button
            v-if="!hiddenCloseBtn"
            class="btn-reset btn-cross btn-cross--modal"
            @click="onClose"
          />

          <h3 v-if="title" class="modal__title">{{ title }}</h3>

          <slot />
        </div>
      </div>
    </div>
<!--  </transition>-->
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    hiddenCloseBtn: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activeClass: ''
  }),

  mounted () {
    this.activeClass = 'modal--active'
  },

  destroyed () {
    this.activeClass = ''
  },

  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
