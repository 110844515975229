/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-avatar': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.222 12.863c1.364 0 2.585-1.095 2.585-2.974 0-1.879-1.22-2.974-2.585-2.974-1.364 0-2.585 1.095-2.585 2.974 0 1.879 1.221 2.974 2.585 2.974zm1.772 1.537c-.54.297-1.14.467-1.772.467-.632 0-1.23-.17-1.772-.467h-1.339C8.393 14.4 7 16.071 7 18.133v.467c0 .773.523 1.4 1.167 1.4h10.11c.645 0 1.167-.627 1.167-1.4v-.467c0-2.062-1.392-3.733-3.11-3.733h-1.34zm1.34.915h-1.117a4.54 4.54 0 01-1.995.467 4.54 4.54 0 01-1.994-.467h-1.117c-1.064 0-2.196 1.098-2.196 2.818v.467c0 .183.063.323.13.404.065.079.113.08.122.08h10.11c.01 0 .057-.001.123-.08.067-.08.13-.22.13-.404v-.467c0-1.72-1.133-2.818-2.197-2.818zm.388-5.426c0 2.319-1.568 3.889-3.5 3.889s-3.5-1.57-3.5-3.89C9.722 7.57 11.29 6 13.222 6c1.933 0 3.5 1.57 3.5 3.889z" _fill="#0E1D3A"/><circle pid="1" cx="13" cy="13" r="11.5" _stroke="#0E1D3A"/>'
  }
})
