import users from '@/store/modules/users'
import productions from '@/store/modules/productions'
import production from '@/store/modules/production'
import catalog from '@/store/modules/catalog'
import warehouse from '@/store/modules/warehouse'
import records from '@/store/modules/records'
import permission from '@/store/modules/permission'
import menu from '@/store/modules/menu/menu'
import scroll from '@/store/modules/scroll'
import handbook from '@/store/modules/handbook'
import calendar from '@/store/modules/calendar'
import alert from '@/store/modules/alert'
import { waybillModel } from '@/entities/waybill'
import reclamationExternal from '@/store/modules/reclamation-external'
import mobile from '@/store/modules/mobile'

export default {
  users,
  productions,
  ...production,
  waybill: waybillModel.store,
  catalog,
  warehouse,
  records,
  permission,
  menu,
  scroll,
  handbook,
  calendar,
  alert,
  reclamationExternal,
  mobile
}
