<template>
  <div class="submenu custom-scroll sidebar" v-if="!headerMenuIsActive"  :class="isCatalogPages && 'catalog-sidebar'">
    <LinkPageBack
      v-if="visibleLinkPageBack"
      class="sidebar__link-page-back"
      route-name="Catalog"
      :route-params="linkPageBackRouteParams"
      text=""
    />

    <nav class="submenu__nav" :class="isCatalogPages && 'catalog-sidebar-nav'">
      <ul class="submenu__list">
        <NavItem
          v-for="(item, index) in sidebarMenu"
          :key="index"
          :navItem="item"
          block="menu"
        />
      </ul>
    </nav>
  </div>
</template>

<script>
import NavItem from '@/components/ui/Nav/NavItem'
import LinkPageBack from '@/components/ui/link/LinkPageBack'
import { mapState } from 'vuex'

export default {
  name: 'TheSidebar',
  components: {
    LinkPageBack,
    NavItem
  },
  computed: {
    ...mapState('menu', {
      headerMenuIsActive: 'headerMenuIsActive'
    }),
    visibleLinkPageBack () {
      return this.categoryId
    },
    isCatalogPages () {
      return this.$route.meta.availableName === 'isCatalog'
    },

    linkPageBackRouteParams () {
      const id = this.sidebarMenu[0]?.parent_id
      return {
        categoryId: id === 1 ? null : id
      }
    },

    categoryId () {
      return this.$route.params.categoryId
    },

    sidebarMenu () {
      return this.$store.state.menu.sidebarMenu
    }
  }
}
</script>
