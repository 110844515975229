<template>
  <div class="button-group buttons buttons-form" :class="onlyBtnSubmit && 'button-group--only-submit'">
    <VButton
      :text="textBtnConfirm"
      type="submit"
      color="primary"
      :size="btnSize"
      :loading="loading"
      :disabled="disabled"
      @click.native="onConfirm"
    />

    <VButton
      v-if="!onlyBtnSubmit"
      :text="textBtnReject"
      :size="btnSize"
      :disabled="disabled"
      @click.native="onReject"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton'

import { mixinButtonGroup } from '@/mixins/button/mixinButtonGroup'

export default {
  name: 'ButtonGroup',
  components: { VButton },
  mixins: [mixinButtonGroup]
}
</script>
