import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import user from '@/store/index.js'

import { getAll } from '@/api/request'
import { getConsignmentStatusIdByRouteName } from '@/utils/order/consignment'
import { deleteKeyFormData } from '@/utils/form/deleteKeyFormData'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import { setAlertError } from '@/utils/store/alert'
import { getPageCount } from '@/utils/store/pagination'
import { dateFilter } from '@/filters/date.filter'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'

const state = () => ({
  urlConsignmentAmount: 'consignment/amount',

  consignmentList: [],
  consignment: {},
  isLoadingConsignmentList: false,

  // Query
  pagination: {
    perPage: 20,
    page: 1,
    pageCount: 1
  },
  filter: {},

  defaultTableColumns: [
    {
      title: '№ счета',
      sortable: true,
      sortDesc: true,
      nowrap: true,
      name: 'positions.orders.account_number',
      isChildItem: true,
      childKeys: {
        category: 'order',
        categoryItem: 'account_number'
      }
    },
    {
      title: '№ поз.',
      sortable: true,
      sortDesc: true,
      nowrap: true,
      name: 'positions.sort',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'sort'
      }
    },
    {
      title: '№ пар.',
      sortable: true,
      sortDesc: true,
      nowrap: true,
      name: 'sort'
    },
    {
      title: 'Кол-во',
      sortable: true,
      sortDesc: true,
      nowrap: true,
      name: 'value'
    },
    {
      title: 'Тип пружины',
      sortable: true,
      sortDesc: true,
      name: 'positions.types.name',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'type',
        categoryItemSecond: 'name'
      }
    },
    {
      title: 'Материал',
      sortable: true,
      sortDesc: true,
      name: 'positions.materials.name',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'material',
        categoryItemSecond: 'name'
      }
    },
    {
      title: 'd пров.',
      sortable: true,
      sortDesc: true,
      nowrap: true,
      name: 'positions.d_wire',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'd_wire'
      }
    },
    {
      title: 'Покрытие',
      sortable: true,
      sortDesc: true,
      name: 'positions.coatings.name',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'coating',
        categoryItemSecond: 'name'
      }
    },
    {
      title: 'Цвет',
      sortable: true,
      sortDesc: true,
      name: 'positions.colors.name',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'color',
        categoryItemSecond: 'name'
      }
    },
    {
      title: 'Цех производства',
      sortable: true,
      sortDesc: true,
      name: 'positions.workshops.name',
      isChildItem: true,
      childKeys: {
        category: 'position',
        categoryItem: 'workshop',
        categoryItemSecond: 'name'
      }
    },
    {
      title: 'Этап',
      sortable: true,
      sortDesc: true,
      name: 'statuses.name',
      isChildItem: true,
      childKeys: {
        category: 'status',
        categoryItem: 'name'
      }
    },
    {
      title: 'Дата отгрузки',
      sortable: true,
      sortDesc: true,
      name: 'positions.orders.date_finished',
      isChildItem: true,
      childKeys: {
        category: 'order',
        categoryItem: 'date_finished'
      },
      handlerFormat: dateFilter
    },
    {
      title: 'След. этап',
      name: 'next_status',
      isChildItem: true,
      childKeys: {
        category: 'next_status',
        categoryItem: 'name'
      }
    }
  ],

  savedTableColumns: [],
  sort: '-id'
})

const getters = {
  consignment: state => state.consignment,
  consignmentReclamationInnerList: state => {
    return state.consignment.inner_reclamations
  },
  getQueryParams: (state, _, rootState) => {
    let params = {
      account_number: rootState.registerPage.search || null,
      ...state.filter,
      overdue: rootState.order.filterByDateStatus.overdue || null,
      deadline_is_coming: rootState.order.filterByDateStatus.deadline_is_coming || null,
      consignment_id: rootState.registerPage.queryParams?.consignment_id || null
    }

    const deleteKeyList = [
      // 'application_area_id',
      'user_id'
    ]
    params = deleteKeyFormData(params, deleteKeyList)

    const keyListForIdArray = [
      'stage'
    ]
    params = prepareFormDataIdArray(params, keyListForIdArray)

    if (params.sort) {
      if (params.sort === 'date_finished') {
        params.sort = 'positions.orders.date_finished'
      }
      if (params.sort === 'timings.days') {
        params.sort = 'positions.orders.timings.days'
      }
    }

    return params
  },

  tableColumnsDefault: (state) => {
    return state.defaultTableColumns
  },

  tableColumnsCurrent: (state) => {
    const userSettings = user.getters.currentUser?.meta?.views?.consignments

    if (userSettings) {
      const columnCurrent = []
      userSettings.forEach(function (item) {
        const findColumn = state.defaultTableColumns.find((el) => el.name === item)
        if (findColumn) columnCurrent.push(findColumn)
      })

      return columnCurrent
    } else {
      return state.defaultTableColumns
    }
  },

  getSort: (state) => {
    return state.sort
  }
}

const mutations = {
  SET_CONSIGNMENT_LIST: (state, list) => {
    state.consignmentList = [...list]
  },

  SET_CONSIGNMENT: (state, consignment) => {
    const temp = state.consignment || {}
    state.consignment = { ...temp, ...consignment }
  },

  ADD_CONSIGNMENT: (state, consignment) => {
    state.consignmentList.push(consignment)
  },
  UPDATE_CONSIGNMENT: (state, consignment) => {
    const index = state.consignmentList.findIndex((elem) => elem.id === consignment.id)
    const tempConsignment = state.consignmentList[index]
    Vue.set(state.consignmentList, index, { ...tempConsignment, ...consignment })
  },
  REMOVE_CONSIGNMENT: (state, id) => {
    state.consignmentList = state.consignmentList.filter(c => c.id !== id)
  },

  SET_IS_LOADING_CONSIGNMENT_LIST: (state, loading) => {
    state.isLoadingConsignmentList = loading
  },

  // Query
  SET_FILTER (state, payload) {
    state.filter = payload
  },
  SET_PAGE (state, pageNumber) {
    state.pagination.page = pageNumber
  },
  SET_PAGINATION_PAGE_COUNT (state, pageCount) {
    state.pagination.pageCount = pageCount
  },

  // RESET
  CLEAR_STATE (state) {
    state.consignmentList = []
    state.consignment = {}
  },

  SET_SORT (state, sort) {
    state.sort = sort
  },

  RESET_SORT: (state) => {
    state.defaultTableColumns.forEach(function (item) {
      if (!item?.sortDesc) item.sortDesc = true
    })
  }
}

const actions = {
  async fetchConsignmentListData ({ commit, dispatch }) {
    commit('SET_IS_LOADING_CONSIGNMENT_LIST', true)

    try {
      await axios.all([
        dispatch('getConsignmentAll'),
        dispatch('getAmountTab'),
        dispatch('order/loadOrderCountByDateStatus', null, { root: true })
      ])
    } finally {
      commit('SET_IS_LOADING_CONSIGNMENT_LIST', false)
    }
  },
  async getConsignmentAll ({ state, commit, getters }) {
    const params = {
      status_line: getConsignmentStatusIdByRouteName(router.currentRoute.name),
      sort: state.sort ?? '-id',
      ...getters.getQueryParams,
      'per-page': state.pagination.perPage,
      page: state.pagination.page
    }

    try {
      const response = await ConsignmentController.getAll(params)
      if (response?.status !== 200) return
      commit('SET_CONSIGNMENT_LIST', response.data)
      commit('SET_PAGINATION_PAGE_COUNT', getPageCount(response))
    } finally {
      commit('SET_IS_LOADING_CONSIGNMENT_LIST', false)
    }
  },
  async loadPage ({ commit, dispatch }, pageNumber) {
    commit('SET_PAGE', pageNumber)
    await dispatch('fetchConsignmentListData')
  },

  async getConsignment ({ commit }, id) {
    const response = await ConsignmentController.getById(id)
    if (response?.status !== 200) return

    commit('SET_CONSIGNMENT', response.data)
  },

  async updateConsignment ({ state, commit, rootGetters, dispatch }, data) {
    const postData = {
      changer_id: rootGetters.currentUserId,
      ...data
    }

    const response = await ConsignmentController.update(data.id, postData)
    if (response?.status !== 200) return
    commit('UPDATE_CONSIGNMENT', response.data)
    commit('SET_CONSIGNMENT', response.data)
  },

  DELETEConsignment ({ state, dispatch }, id) {
    return ConsignmentController.destroy(id)
  },

  async getAmountTab ({ commit, state, getters }) {
      try {
        const res = await getAll(state.urlConsignmentAmount, getters.getQueryParams)
        commit(
          'tabsOrderList/SET_TABS_AMOUNT',
          { tabsName: 'Consignment', data: res.data },
          { root: 'true' }
        )
      } catch (e) {
        await setAlertError(e, 'Не удалось загрузить количество партий по статусам')
      }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
