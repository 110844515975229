<template>
  <div class="base-input" :class="{ error: hasValidationError }">
    <label
      @click="() => !readonly && $refs.input.focus()"
      v-if="label"
      :class="['input__label', { readonly }]"
    >
      {{ label }}
      <span v-if="required">*</span>
    </label
    >
    <div class="input__field" v-if="readonly">
      {{ inputValue }}
    </div>
    <textarea
      autocomplete="off"
      ref="input"
      v-model="inputValue"
      @focus="focus"
      @blur="handleBlur"
      @input="handleInput"
      :name="name"
      :placeholder="placeholder"
      class="input__field input__field_textarea custom-scroll"
      :style="textareaStyle"
      :readonly="readonly"
      :disabled="disabled"
      :maxlength="limitMaxLength"
      v-else
    />
    <slot
      v-if="hasValidationError && !serverValidationPending"
      class="validation-messages"
    />
  </div>
</template>

<script>
import Base from './Base/Base.vue'

export default {
  name: 'vtextarea',
  extends: Base,
  methods: {
    handleBlur () {
      this.$emit('blur')
      this.validate()
    },
    focus () {
      this.$refs.input.focus()
    },
    handleInput (e) {
      const el = e.target
      const sel = el.selectionStart

      this.$nextTick(() => {
        el.setSelectionRange(sel, sel)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  overflow: auto;
  max-width: 100%;
  scrollbar-width: none;
  scrollbar-color: transparent;
  color: $color__input--font;
  font: inherit;
  border-radius: 8px;
  border: 1px solid $color__input--border;
  padding: 10px 15px;
  height: 110px;

  @media screen and (max-width: $tablet-width-s) {
    border-color: var(--color__pearl-dark-gray);
  }

  &::placeholder {
    letter-spacing: 0.01em;
    color: $color__input--font;
    font-size: 14px;
    font-weight: normal;
  }

  &:focus {
    border: 1px solid $color__input--focus;
    box-shadow: 0 0 0 2px $color__blue--light inset;
  }
}

.readonly-block {
  word-break: break-word;
  line-height: 150%;
}

// ::-webkit-scrollbar {
//   width: 0;
//   background: transparent;
// }
</style>
