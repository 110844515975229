import { pages } from './pages'

export const sidebar = [
  {
    label: 'Таблица сроков и&nbsp;нагрузки',
    labelMobile: 'Сроки/нагрузки',
    routeName: pages.tableTimeAndLoad,
    routeParams: { id: 1 },
    icon: 'table-time-and-load'
  },
  {
    label: 'Журнал изменений',
    labelMobile: 'Журнал',
    routeName: pages.changelog,
    icon: 'changelog'
  },
  {
    label: 'Производственный календарь',
    labelMobile: 'Календарь',
    routeName: pages.productionCalendar,
    icon: 'production-calendar'
  }
]
